import { SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Row, Select } from 'antd';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IListColumn, ISortColumn, PaginationResponse } from 'src/@types/search-params';
import TableContent from 'src/components/CTable';
import { useLocation, useSearchParams } from 'react-router-dom';
import { orderStatus } from 'src/shared/common/package_assigned';
import { exportExcel, formatDate, formatDateTime, formatMoney, parseQueryString } from 'src/utils';
import { getAllPackageAssigned, getPackageAssignedV2 } from 'src/api/package_assigned';
import { IOrderStatus, IPackageAssigned } from 'src/shared/interface/package_assigned';
import { useDispatch } from 'react-redux';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { useAppSelector } from 'src/redux/hook';
import { SimType } from 'src/shared/enum/sim.enum';
import { simTypeToText } from 'src/utils/sim.enum';
import {
  getAllCancelPackage,
  getAllDataPackage,
  getAllRegisterPackage,
} from 'src/api/register_and_cancel_package';

function CancelListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const params = location.search;
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const { openTabs } = useAppSelector((state) => state.global);

  const [data, setData] = useState<PaginationResponse<IPackageAssigned[]>>();
  const [sortColumn, setSortColumn] = useState<ISortColumn>();
  const [dataPackage, setDataPackage] = useState<IOrderStatus[]>([]);

  const fetchGetPackageAssigned = async () => {
    getAllDataPackage()
      .then((res: any) => {
        if (res) {
          const dataPackage: IOrderStatus[] = [{ value: '', label: 'Tất cả' }];
          res?.map((item: any) => {
            dataPackage?.push({
              label: item,
              value: item,
            });
          });
          setDataPackage(dataPackage);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function handleExport() {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `danh_sach_huy_goi_${moment().format('DDMMYYYYHHmmss')}.xlsx`;
    exportExcel(
      process.env.REACT_APP_API_URL + '/api/DataPackage/cancel/export-excel' + params,
    ).then((res) => {
      link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
      link.click();
    });
  }

  function handleFormSearch(value: any) {
    let values = { ...value };
    values = {
      ...values,
      dateFrom:
        value.dateRange && value?.dateRange[0]
          ? value?.dateRange[0].format('YYYY-MM-DD')
          : value.dateFrom ?? undefined,
      dateTo:
        value.dateRange && value?.dateRange[1]
          ? value?.dateRange[1].format('YYYY-MM-DD')
          : value.dateTo ?? undefined,
      dateRange: undefined,
    };
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        delete values[key];
      }
    });
    setSearchParams({ pageIndex: 1, pageSize: 10, ...values });
  }

  function openCreateCancelPackagePage() {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'register-and-cancel-package/cancel/create',
      }),
    );
    if (!openTabs.find((tab) => tab.key === 'register-and-cancel-package/cancel/create')) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Tạo mới đơn hàng Huỷ gói',
              children: '',
              key: 'register-and-cancel-package/cancel/create',
              closable: true,
            },
          ],
        }),
      );
    }
    dispatch(setGlobalState({ order: undefined }));
    navigate(`/management/register-and-cancel-package/cancel/create`);
  }

  const handleDetailCancel = (order: any) => {
    dispatch(
      setGlobalState({
        activeKeyMenu: 'register-and-cancel-package/cancel/detail' + '?id=' + order.id,
      }),
    );
    if (
      !openTabs.find(
        (tab) => tab.key === 'register-and-cancel-package/cancel/detail' + '?id=' + order.id,
      )
    ) {
      dispatch(
        setGlobalState({
          openTabs: [
            ...openTabs,
            {
              label: 'Xem chi tiết Huỷ gói ' + order.id,
              children: '',
              key: 'register-and-cancel-package/cancel/detail' + '?id=' + order.id,
              closable: true,
            },
          ],
        }),
      );
    }
    navigate(`/management/register-and-cancel-package/cancel/detail?id=${order.id}`);
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    {
      name: 'Mã yêu cầu',
      fieldName: 'id',
      renderSpecialCard: (order: IPackageAssigned) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => handleDetailCancel(order)}
        >
          {order.id ?? ''}
        </button>
      ),
    },
    {
      name: 'Mã gói',
      fieldName: 'mbfCode',
      isHideSort: false,
    },
    {
      name: 'Trạng thái',
      fieldName: 'statusString',
      renderSpecialCard: (record: IPackageAssigned) => {
        const status = orderStatus.find((item) => item.value === record.statusString);
        return <span className='whitespace-nowrap'>{status?.label ?? ''}</span>;
      },
    },
    {
      name: 'Tổng cộng',
      fieldName: 'countSerial',
      isHideSort: true,
      width: '5%',
    },
    {
      name: 'Thành công',
      fieldName: 'quantitySuccess',
      isHideSort: true,
      width: '5%',
    },
    {
      name: 'Thất bại',
      fieldName: 'quantityFailed',
      isHideSort: true,
      width: '5%',
    },
    {
      name: 'Người tạo',
      fieldName: 'createdBy',
    },
    {
      name: 'Ngày tạo',
      fieldName: 'createdAt',
      renderSpecialCard: (record: IPackageAssigned) => {
        return <span>{record.createdAt ? formatDate(record.createdAt) : ''}</span>;
      },
    },
    {
      name: 'Ngày cập nhật cuối',
      fieldName: 'updatedAt',
      renderSpecialCard: (record: IPackageAssigned) => {
        return (
          <span>
            {record.updatedAt &&
            record.createdAt &&
            new Date(record.updatedAt).getTime() === new Date(record.createdAt).getTime()
              ? ''
              : record.updatedAt
              ? formatDate(record.updatedAt)
              : ''}
          </span>
        );
      },
    },
    {
      name: 'Người cập nhật cuối',
      fieldName: 'updatedBy',
    },
    {
      name: 'Thao tác',
      fieldName: 'id',
      renderSpecialCard: (order) => (
        <button
          className={`underline whitespace-nowrap cursor-pointer bg-transparent border-transparent text-blue-400 font-semibold`}
          onClick={() => handleDetailCancel(order)}
        >
          Xem chi tiết
        </button>
      ),
      isHideSort: true,
    },
  ];

  const fetchListPackageAssigned = async () => {
    const params = parseQueryString(location.search);

    const res = await getAllCancelPackage(params);
    if (res) {
      setData(res);
    }
  };

  useEffect(() => {
    fetchGetPackageAssigned();
  }, []);

  useEffect(() => {
    fetchListPackageAssigned();
    setSortColumn(undefined);
  }, [location.search]);

  return (
    <Fragment>
      <Form onFinish={handleFormSearch} layout='inline'>
        <Row className='gap-4'>
          <Form.Item name='id'>
            <Input prefix={<SearchOutlined />} placeholder='Mã đơn' allowClear />
          </Form.Item>
          <Form.Item name='mbfCode' label='Mã gói'>
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              defaultValue={''}
              options={dataPackage}
            ></Select>
          </Form.Item>
          <Form.Item name='status' label='Trạng thái'>
            <Select
              showSearch
              className='min-w-[150px]'
              placeholder='Vui lòng chọn'
              defaultValue={''}
              options={orderStatus}
            ></Select>
          </Form.Item>
          <Form.Item name='' label='Người tạo'>
            <Select
              showSearch
              className='min-w-[150px]'
              defaultValue={merchantInfo ? JSON.parse(merchantInfo).merchantName : ''}
            >
              {/* <Select.Option value='merchantName'>Tất cả</Select.Option> */}
              <Select.Option value='merchantName'>
                {merchantInfo ? JSON.parse(merchantInfo).merchantName : ''}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name='dateRange' label='Ngày tạo'>
            <DatePicker.RangePicker
              disabledDate={(current) => current && current >= moment().endOf('day')}
              placeholder={['từ', 'đến']}
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        </Row>

        <div className='row-button' style={{ justifyContent: 'flex-end', margin: '1.5rem 0' }}>
          <Button type='primary' htmlType='submit'>
            Tìm kiếm
          </Button>
          <Button onClick={openCreateCancelPackagePage}>Tạo mới</Button>
          <Button onClick={handleExport}>Export</Button>
        </div>
      </Form>

      <TableContent
        bordered
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: 'Không tìm thấy kết quả' }}
        dataSource={data?.results ?? []}
        columns={columns}
        totalRecord={data?.rowCount ?? 0}
        sortColumn={sortColumn}
        onSort={(sort: ISortColumn) => {
          const pagination = parseQueryString(location.search);
          handleFormSearch({
            ...pagination,
            pageIndex: pagination.pageIndex ?? 1,
            pageSize: pagination.pageSize ?? 10,
            sortField: sort.fieldName,
            asc: sort.orderType === 'asc' ? true : sort.orderType === 'desc' ? 'false' : undefined,
          });
        }}
      />
    </Fragment>
  );
}

export default CancelListPage;
