/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tabs,
  Upload,
  UploadProps,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { cloneDeep } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import { getBalance } from 'src/api/localpay';
import { uploadFilePackageAssigned } from 'src/api/package_assigned';
import {
  checkImportExcelRegisterPackage,
  checkInputPhoneRegister,
  createRegisterPackage,
  deleteRegisterPackage,
  getDetailPackage,
  getRegisterPackage,
  registerAndCancelPackage,
  updatePackage,
} from 'src/api/register_and_cancel_package';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import TableContent from 'src/components/CTable';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setCancelPackageState } from 'src/redux/slice/registerAndCancelPackage';
import { orderStatus } from 'src/shared/common/package_assigned';
import { SimStatusEnum } from 'src/shared/enum/change_sim';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import {
  ICheckInputSerialParamsRequest,
  IOrderStatus,
  IOverFlowOrderDetailResponse,
} from 'src/shared/interface/package_assigned';
import { IRegisterPackage, PackageUpdate } from 'src/shared/interface/register_and_cancel';
import { exportExcelPost, formatDateTime, parseQueryString } from 'src/utils';

const { Search } = Input;
function CreateCancelPackagePage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const watchCode = useWatch('mbfCode', form);
  const merchantInfo: string | null = localStorage.getItem('merchantInfo');
  const location = useLocation();
  const { id } = parseQueryString(location.search);
  const params = parseQueryString(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const { refresh, order, openTabs } = useAppSelector((state) => state.global);
  const { dataSuccessCancel: dataSuccess, dataFailedCancel: dataFailed } = useAppSelector(
    (state) => state.registerAndCancel,
  );

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false);
  const [openModalConfirmChangeMbfCode, setOpenModalConfirmChangeMbfCode] = useState(false);
  const [openModalConfirmChangeFail, setOpenModalConfirmChangeFail] = useState(false);
  const [openModalConfirmChangeCosType, setOpenModalConfirmChangeCosType] = useState(false);

  const [dataItemChangeFail, setDataItemChangeFail] = useState<IOverFlowOrderDetailResponse>();
  const [radio, setRadio] = useState<number>(1);
  const [isImportFile, setIsImportFile] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusPackage, setStatusPackage] = useState<string>(OrderStatusEnum.New);
  const [cosTypeTemp, setCosTypeTemp] = useState<string>('1');
  const [dataPackage, setDataPackage] = useState<IOrderStatus[]>([]);
  const [infoPackageInput, setInfoPackageInput] = useState<IOverFlowOrderDetailResponse>();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [listEsimValid, setListEsimValid] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const [selectStatus, setSelectStatus] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('1');
  const [oriErros, setOriErros] = useState('');
  const [oriSuccess, setOriSuccess] = useState('');
  const [listErrors, setListErros] = useState<IOverFlowOrderDetailResponse[]>(
    location.search ? [] : dataFailed,
  );
  const [listSuccess, setListSuccess] = useState<IOverFlowOrderDetailResponse[]>(
    location.search ? [] : dataSuccess,
  );
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [fileImport, setFileImport] = useState<any>(null);
  const [pathFile, setPathFile] = useState<any>(null);
  const [currentClick, setCurrentClick] = useState('');

  useEffect(() => {
    const fetchBalance = async () => {
      const balanceRes = await getBalance();
      if (balanceRes) {
        dispatch(setGlobalState({ balance: balanceRes.balance }));
      }
    };
    dispatch(
      setCancelPackageState({
        dataSuccessCancel: id ? dataSuccess : [],
        dataFailedCancel: id ? dataFailed : [],
      }),
    );
    dispatch(setGlobalState({ order: undefined }));
    fetchBalance();
    return () => {
      dispatch(
        setCancelPackageState({
          dataSuccessCancel: id ? dataSuccess : [],
          dataFailedCancel: id ? dataFailed : [],
        }),
      );
    };
  }, []);
  useEffect(() => {
    setListErros(dataFailed);
    setListSuccess(dataSuccess);
  }, [dataSuccess, dataFailed]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (statusPackage === OrderStatusEnum.Processing) {
        fetchDetailOrder();
      }
    }, 30000);
    if (statusPackage !== OrderStatusEnum.Processing) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [statusPackage]);

  const fetchDetailOrder = async () => {
    const res = await getDetailPackage('cancel', id);
    if (res) {
      if (res.fileUrl) {
        setRadio(2);
        setPathFile(res.fileUrl);
      }
      const status = orderStatus.find((item) => item.value === res.statusString);
      form.setFieldsValue({
        ...res,
        cosType: res.cosType ? res.cosType.toString() : '1',
        statusString: status?.label ? status.label : '',
      });
      fetchGetPackageAssigned(res?.cosType ?? '');
      setCosTypeTemp(res?.cosType ?? '');
      let listCheckSuccess: IOverFlowOrderDetailResponse[] = [];
      const listCheckFail: IOverFlowOrderDetailResponse[] = [];
      const listEsimValidTmp: any = [];
      if (res.statusString !== 'New') {
        listCheckSuccess = res.overFlowOrderDetailResponses ? res.overFlowOrderDetailResponses : [];
      } else {
        res.overFlowOrderDetailResponses?.map((item: any, index: number) => {
          if (res.statusString === OrderStatusEnum.New) {
            listCheckSuccess.push({ ...item, key: index });
          } else if (item.statusString === OrderStatusEnum.Failed) {
            if (item.id) {
              const errorItem = {
                code: item.errorCode,
                message: item.errorMessage,
                serial: item.serial,
              };
              listCheckFail.push(errorItem);
            } else {
              listCheckFail.push(item);
            }
          } else {
            listCheckSuccess.push(item);
          }

          if (item?.statusString === 'New') {
            listEsimValidTmp.push(item);
          }
        });
      }
      setListSuccess(listCheckSuccess);
      setListErros([]);
      setOriSuccess(JSON.stringify(res.overFlowOrderDetailResponses));
      dispatch(setCancelPackageState({ dataSuccessCancel: id ? listCheckSuccess : [] }));
      if (res.statusString !== OrderStatusEnum.New) {
        dispatch(setCancelPackageState({ dataFailedCancel: id ? listCheckFail : [] }));
      }
      dispatch(setGlobalState({ order: res }));
      setStatusPackage(res.statusString ? res.statusString.toString() : '');
      setInfoPackageInput(undefined);
      setListEsimValid(listEsimValidTmp);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetailOrder();
    } else {
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setCancelPackageState({ dataSuccessCancel: [], dataFailedCancel: [] }));
      form.resetFields();
    }

    return () => {
      setStatusPackage(OrderStatusEnum.New);
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setCancelPackageState({ dataSuccessCancel: [], dataFailedCancel: [] }));
    };
  }, [id, refresh]);

  const fetchGetPackageAssigned = async (type?: string) => {
    await getRegisterPackage(type ? type : form.getFieldValue('cosType') ?? undefined, "3").then(
      (res: any) => {
        if (res) {
          const dataPackage: IOrderStatus[] = [];
          res?.map((item: any) => {
            dataPackage?.push({
              label: item,
              value: item,
            });
          });

          setCosTypeTemp(type ? type : form.getFieldValue('cosType') ?? '');
          setDataPackage(dataPackage);
        }
      },
    );
  };

  const addPhoneNumber = (phoneNumber: number) => {
    if (form.getFieldValue('mbfCode')) {
      const params: ICheckInputSerialParamsRequest[] = [
        {
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          orderId: id ? id : undefined,
          phoneNumber: phoneNumber,
        },
      ];

      checkInputPhoneRegister('cancel', params)
        .then((res) => {
          if (res) {
            const newDataSuccess: IOverFlowOrderDetailResponse[] = [...dataSuccess];
            const newDataFailed: IOverFlowOrderDetailResponse[] = [...dataFailed];
            if (pathFile) {
              setFileImport(null);
              setPathFile('');
              setFileInfo(null);
              setListSuccess([]);
              setListErros([]);
              setOriSuccess('');
              setOriErros('');
            } else {
              //Xử lí duplicate
              [...res.dataSuccess].forEach((item) => {
                //Trường hợp không trùng bất cứ thằng nào.
                if (!dataSuccess.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                  newDataSuccess.push(item);
                } else {
                  const findIndex = dataSuccess.findIndex(
                    (predicate) => predicate.phoneNumber === item.phoneNumber,
                  );
                  newDataSuccess[findIndex] = {
                    ...item,
                    errorCode: item?.errorCode ? item.errorCode : '',
                    errorMessage: item?.errorMessage ? item.errorMessage : '',
                  };
                }
              });
              [...res.dataError].forEach((item) => {
                //Trường hợp không trùng bất cứ thằng nào.
                if (!dataFailed.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                  newDataFailed.unshift(item);
                } else {
                  const findIndex = dataFailed.findIndex(
                    (predicate) => predicate.phoneNumber === item.phoneNumber,
                  );
                  newDataFailed[findIndex] = item;
                }
              });
            }
            setListSuccess(pathFile ? res.dataSuccess : newDataSuccess);
            setListErros(pathFile ? res.dataError : newDataFailed);
            setOriSuccess(
              pathFile ? JSON.stringify(res.dataSuccess) : JSON.stringify(newDataSuccess),
            );
            setOriErros(pathFile ? JSON.stringify(res.dataError) : JSON.stringify(newDataFailed));

            dispatch(
              setCancelPackageState({
                dataFailedCancel: pathFile ? res.dataError : newDataFailed,
                dataSuccessCancel: pathFile ? res.dataSuccess : newDataSuccess,
              }),
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.warning(`Vui lòng chọn mã gói!`);
    }
  };

  const handleImport = async (file: any) => {
    if (radio === 2) {
      const fileImportExcel = fileImport || file
      if (form.getFieldValue('mbfCode') && fileImportExcel) {
        const res = await checkImportExcelRegisterPackage('cancel', {
          excelFile: fileImportExcel?.originFileObj ? fileImportExcel.originFileObj : fileImportExcel,
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          orderId: id ? id : undefined,
        });
        if (res) {
          const unique = res.dataError.filter(
            (obj, index) =>
              res.dataError.findIndex(
                (item) => item.serialNew === obj.serialNew && item.phoneNumber === obj.phoneNumber,
              ) === index,
          );
          setListSuccess(res.dataSuccess);
          setListErros(unique);
          setOriSuccess(JSON.stringify(res.dataSuccess));
          setOriErros(JSON.stringify(unique));
          dispatch(
            setCancelPackageState({
              dataSuccessCancel: res.dataSuccess ?? [],
              dataFailedCancel: res.dataError ?? [],
            }),
          );
        }
        setOpenModalConfirm(false);
      } else {
        message.warning(`Vui lòng chọn mã gói!`);
      }
    } else {
      setOpenModalConfirm(true); // Hiển thị modal xác nhận
    }
    setIsImportFile(true);
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      const isPNG =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (!isPNG) {
        message.error(`${file.name} không đúng định dạng`);
      }
      return isPNG;
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFileImport(null);
      } else {
        file.status = 'done';
        setFileImport(file);
        if (id) {
          setOpenModalConfirm(true);
        } else {
          handleImport(file);
        }
      }
    },
    customRequest: async (info) => {
      if (watchCode) {
        uploadFilePackageAssigned(info.file).then((res) => {
          setPathFile(res);
        });
      } else {
        message.error(`Vui lòng chọn mã gói`);
      }
    },
  };

  const handleSubmit = async () => {
    if (statusPackage === OrderStatusEnum.New) {
      if ([...listSuccess].length === 0) {
        message.error('Vui lòng import số!');
      } else {
        try {
          let getFileUrl = '';
          if (radio === 2) {
            getFileUrl = await uploadFilePackageAssigned(
              fileImport?.originFileObj ? fileImport.originFileObj : fileImport,
            );
          }
          const listSerial = dataSuccess.map((item) => {
            return {
              phoneNumber: item.phoneNumber ?? '',
              mbfCode: item.mbfCode ?? '',
              expiredPackageDate: item.expiredPackageDate ? item.expiredPackageDate : undefined,
            };
          });
          const params: IRegisterPackage = {
            mbfCode: form.getFieldValue('mbfCode') ?? '',
            data: listSerial,
            fileUrl: getFileUrl ? getFileUrl : undefined,
            fileName: fileImport ? fileImport.name : undefined,
          };

          const res = await createRegisterPackage('cancel', params);
          if (res) {
            form.setFieldValue('mbfCode', '');
            message.success('Lưu đơn hàng thành công!');
          }
          const newPanes = openTabs.filter(
            (pane) => pane.key !== 'register-and-cancel-package/cancel/create',
          );

          dispatch(
            setGlobalState({
              activeKeyMenu: 'register-and-cancel-package/cancel/detail' + '?id=' + res.id,
              refresh: !refresh,
            }),
          );
          if (
            !newPanes.find(
              (tab) => tab.key === 'register-and-cancel-package/cancel/detail' + '?id=' + res.id,
            )
          ) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Xem chi tiết Huỷ gói ' + res.id,
                    children: '',
                    key: 'register-and-cancel-package/cancel/detail' + '?id=' + res.id,
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/register-and-cancel-package/cancel/detail?id=${res.id}`);
        } catch (error) {
          console.log(error);

          // navigate(`/management/register-and-cancel-package/register/list`);
        }
      }
    }
    if (statusPackage === OrderStatusEnum.EDIT) {
      if (form.getFieldValue('mbfCode') && listSuccess.length > 0) {
        let getFileUrl = '';
        if (radio === 2 && fileImport) {
          getFileUrl = await uploadFilePackageAssigned(
            fileImport?.originFileObj ? fileImport.originFileObj : fileImport,
          );
        }
        const listSerial = dataSuccess.map((item) => {
          return {
            phoneNumber: item.phoneNumber ?? '',
            mbfCode: item.mbfCode ?? '',
            expiredPackageDate: item.expiredPackageDate ? item.expiredPackageDate : undefined,
          };
        });
        const data: PackageUpdate = {
          orderId: id,
          data: listSerial,
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          fileUrl: getFileUrl ? getFileUrl : undefined,
          fileName: fileImport ? fileImport.name : undefined,
        };
        await updatePackage('cancel', data);
        form.setFieldValue('mbfCode', '');
        dispatch(setGlobalState({ refresh: !refresh }));
        setStatusPackage(OrderStatusEnum.New);
      } else {
        message.error('Vui lòng nhập danh sách thuê bao Huỷ gói!');
      }
    }
  };

  const handleDeletePackage = async () => {
    if (id) {
      try {
        const res = await deleteRegisterPackage(id);
        if (res) {
          message.success('Xoá đơn hàng thành công!');
          const newPanes = openTabs.filter(
            (pane) => pane.key !== 'register-and-cancel-package/cancel/detail',
          );
          dispatch(
            setGlobalState({
              activeKeyMenu: 'register-and-cancel-package/cancel/list',
            }),
          );
          if (!newPanes.find((tab) => tab.key === 'register-and-cancel-package/cancel/list')) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Đơn hàng Huỷ gói',
                    children: '',
                    key: 'register-and-cancel-package/cancel/list',
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/register-and-cancel-package/cancel/list`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleChangeMbfCode = async () => {
    if (form.getFieldValue('mbfCode')) {
      const params: ICheckInputSerialParamsRequest[] = [];
      dataSuccess.forEach((predicate) => {
        params.push({
          mbfCode: form.getFieldValue('mbfCode') ?? '',
          orderId: id ? id : undefined,
          phoneNumber: predicate.phoneNumber,
        });
      });
      checkInputPhoneRegister('cancel', params)
        .then((res) => {
          if (res) {
            const newDataSuccess: IOverFlowOrderDetailResponse[] = [...res.dataSuccess];
            const newDataFailed: IOverFlowOrderDetailResponse[] = [...res.dataError];
            if (pathFile) {
              setFileImport(null);
              setPathFile('');
              setFileInfo(null);
              setListSuccess([]);
              setListErros([]);
              setOriSuccess('');
              setOriErros('');
            }

            setListSuccess(newDataSuccess);
            setListErros(newDataFailed);
            setOriSuccess(
              pathFile ? JSON.stringify(res.dataSuccess) : JSON.stringify(newDataSuccess),
            );
            setOriErros(pathFile ? JSON.stringify(res.dataError) : JSON.stringify(newDataFailed));

            dispatch(
              setCancelPackageState({
                dataFailedCancel: newDataFailed,
                dataSuccessCancel: newDataSuccess,
              }),
            );
            setOpenModalConfirmChangeMbfCode(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.warning(`Vui lòng chọn mã gói!`);
    }
  };

  const handleChangeMbfCodeItem = async (data: IOverFlowOrderDetailResponse) => {
    const assignSuccess = cloneDeep(dataSuccess);
    const dataIndex = assignSuccess.findIndex(
      (predicate) => predicate.phoneNumber === data.phoneNumber,
    );

    const params: ICheckInputSerialParamsRequest = {
      mbfCode: data.mbfCode ?? '',
      orderId: id ? id : undefined,
      phoneNumber: data.phoneNumber ?? '',
    };
    checkInputPhoneRegister('cancel', [params])
      .then((res) => {
        if (res) {
          if (res?.dataSuccess.length) {
            assignSuccess[dataIndex].mbfCode = res.dataSuccess[0].mbfCode;
            assignSuccess[dataIndex].price = undefined;
            assignSuccess[dataIndex].errorCode = res.dataSuccess[0]?.errorCode
              ? res.dataSuccess[0].errorCode
              : '';
            assignSuccess[dataIndex].errorMessage = res.dataSuccess[0]?.errorMessage
              ? res.dataSuccess[0].errorMessage
              : '';
            setListSuccess(assignSuccess);
            setOriSuccess(JSON.stringify(assignSuccess));
            dispatch(
              setCancelPackageState({
                dataSuccessCancel: assignSuccess,
              }),
            );
          } else if (res?.dataError.length) {
            setDataItemChangeFail(res?.dataError[0]);
            setOpenModalConfirmChangeFail(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeMbfFail = () => {
    if (dataItemChangeFail) {
      const newDataSuccess: IOverFlowOrderDetailResponse[] = [...dataSuccess].filter(
        (predicate) => predicate.phoneNumber !== dataItemChangeFail?.phoneNumber,
      );
      const newDataFailed: IOverFlowOrderDetailResponse[] = [...dataFailed, dataItemChangeFail];

      setListSuccess(newDataSuccess);
      setListErros(newDataFailed);
      setOriSuccess(JSON.stringify(newDataSuccess));
      setOriErros(JSON.stringify(newDataFailed));

      dispatch(
        setCancelPackageState({
          dataFailedCancel: newDataFailed,
          dataSuccessCancel: newDataSuccess,
        }),
      );
    }
    setOpenModalConfirmChangeFail(false);
  };

  const handleCancel = async () => {
    try {
      const res = await registerAndCancelPackage('cancel', {
        orderId: id ?? '',
        updatedBy: merchantInfo ? JSON.parse(merchantInfo).merchantName : '',
      });
      if (res) {
        setOpenModalConfirmCancel(false);
        dispatch(setGlobalState({ refresh: !refresh }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchItem = () => {
    if (!valueSearch && !selectStatus) {
      if (activeTab === '2' && oriErros) {
        try {
          setListErros(JSON.parse(oriErros));
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          setListSuccess(JSON.parse(oriSuccess));
        } catch (error) {
          console.log(error);
        }
      }
    } else if (activeTab === '2' && oriErros) {
      const dataError = JSON.parse(oriErros);

      setListErros(
        dataError.filter(function (item: any) {
          if (
            valueSearch &&
            (item.phoneNumber?.includes(valueSearch) ||
              item.userName?.includes(valueSearch) ||
              item.serial?.includes(valueSearch) ||
              item.serialNew?.includes(valueSearch))
          ) {
            return true;
          }
          if (selectStatus) {
            console.log('selectStatus');
          } else {
            return false;
          }
        }),
      );
    } else if (activeTab === '1' && oriSuccess) {
      const dataSuccess = JSON.parse(oriSuccess);

      setListSuccess(
        dataSuccess.filter(function (item: any) {
          if (
            valueSearch &&
            item.phoneNumber.includes(valueSearch) &&
            selectStatus &&
            selectStatus === item.statusString
          ) {
            return true;
          } else if (valueSearch && item.phoneNumber.includes(valueSearch) && !selectStatus) {
            return true;
          } else if (selectStatus && selectStatus === item.statusString && !valueSearch) {
            return true;
          } else if (!selectStatus && !valueSearch) {
            return true;
          } else {
            return false;
          }
        }),
      );
    }
    setSearchParams({
      ...searchParams,
      ...params,
      pageIndex: 1,
    });
  };

  const operations = (
    <div>
      <Input
        placeholder='Số thuê bao'
        onChange={(e) => {
          setValueSearch(e.target.value.trim());
        }}
        style={{ width: 300 }}
        allowClear
      />

      <span className='mx-[12px]'>Trạng thái </span>
      <Select
        onSelect={(e) => {
          setSelectStatus(e);
        }}
        className='w-[120px]'
        placeholder='Vui lòng chọn'
        defaultValue={''}
        options={[
          {
            value: '',
            label: 'Tất cả',
          },
          {
            value: SimStatusEnum.Success,
            label: 'Success',
          },
          {
            value: SimStatusEnum.Failed,
            label: 'Failed',
          },
          // {
          //   value: SimStatusEnum.New,
          //   label: 'New',
          // },
          // {
          //   value: SimStatusEnum.Processing,
          //   label: 'Processing',
          // },
        ]}
      ></Select>
      <Button className='w-[120px] mx-[12px] ' onClick={() => handleSearchItem()}>
        Tìm kiếm
      </Button>
      <Button className='w-[120px] mr-[12px]' onClick={() => handleExportTemplate()}>
        Export
      </Button>
    </div>
  );

  const filterDataSource = (data: any) => {
    let cloneData = JSON.parse(JSON.stringify(data));
    const count = activeTab === '1' ? listSuccess.length : listErrors.length;
    if (
      count == params.pageSize * (params.pageIndex > 1 ? params.pageIndex - 1 : 1) &&
      params.pageIndex > 1
    ) {
      const data = params;
      data.pageIndex = data.pageIndex > 1 ? data.pageIndex - 1 : 1;
      data.pageSize = 10;
      setSearchParams(data);
    }

    cloneData = cloneData.filter((_error: any, index: any) => {
      // debugger
      const pageIndex = params.pageIndex ? parseInt(params.pageIndex) : 1;
      const pageSize = params.pageSize ? parseInt(params.pageSize) : 10;
      if (pageIndex === 1 && index < pageIndex * pageSize) {
        return true;
      } else if (index < pageIndex * pageSize && index >= (pageIndex - 1) * pageSize) {
        return true;
      } else return false;
    });

    return cloneData;
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    { name: 'SIM serial', fieldName: 'serial', isHideSort: true },
    { name: 'Số thuê bao', fieldName: 'phoneNumber', isHideSort: true },
    {
      name: 'Mã gói đang sử dụng',
      fieldName: 'mbfCodeCurrent',
      isHideSort: true,
      renderSpecialCard: (item: IOverFlowOrderDetailResponse) => {
        return (
          <span>
            {item && item?.mbfCodeCurrent
              ? item?.mbfCodeCurrent
              : infoPackageInput && infoPackageInput?.mbfCodeCurrent
                ? infoPackageInput?.mbfCodeCurrent
                : ''}
          </span>
        );
      },
    },
    {
      name: 'Nơi đăng ký',
      fieldName: 'registrationLocation',
      isHideSort: true,
    },
    {
      name: 'Mã gói huỷ',
      fieldName: 'mbfCode',
      isHideSort: true,
      renderSpecialCard: (item: IOverFlowOrderDetailResponse) => {
        return (
          <Select
            showSearch
            placeholder='Vui lòng chọn'
            options={dataPackage}
            value={item.mbfCode}
            disabled={
              statusPackage === OrderStatusEnum.EDIT ||
                (statusPackage === OrderStatusEnum.New && !id)
                ? false
                : true
            }
            onChange={(e) => {
              if (!id) {
                const assignSuccess = cloneDeep(dataSuccess);
                const dataIndex = assignSuccess.findIndex(
                  (predicate) => predicate.phoneNumber === item.phoneNumber,
                );
                assignSuccess[dataIndex].mbfCode = e;
                setListSuccess(assignSuccess);
                setOriSuccess(JSON.stringify(assignSuccess));
                dispatch(
                  setCancelPackageState({
                    dataSuccessCancel: assignSuccess,
                  }),
                );
              } else {
                handleChangeMbfCodeItem({
                  mbfCode: e,
                  phoneNumber: item.phoneNumber,
                });
              }
            }}
          />
        );
      },
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true },
    {
      name: 'Ngày hết hạn gói',
      fieldName: 'expiredPackageDate',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return (
          // <span>{record.expiredPackageDate ? formatDateTime(record.expiredPackageDate) : ''}</span>
          <span>{record.expiredPackageDate ? record.expiredPackageDate : ''}</span>
        );
      },
    },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Mô tả lỗi', fieldName: 'errorMessage', isHideSort: true },
    {
      name: 'Thao tác',
      renderSpecialCard: (item) => (
        <button
          className={`underline whitespace-nowrap bg-transparent border-transparent text-blue-500 font-semibold ${statusPackage === OrderStatusEnum.EDIT ||
            (statusPackage === OrderStatusEnum.New && !order?.id)
            ? 'cursor-pointer'
            : 'opacity-30'
            }`}
          disabled={
            statusPackage === OrderStatusEnum.EDIT ||
              (statusPackage === OrderStatusEnum.New && !order?.id)
              ? false
              : true
          }
          onClick={() => handleDeletePhoneNumber(item)}
        >
          Xóa
        </button>
      ),
      isHideSort: true,
    },
  ];

  const handleDeletePhoneNumber = (dataDelete: IOverFlowOrderDetailResponse) => {
    let listSuccessTemp = cloneDeep(dataSuccess);
    let listErrorsTemp = cloneDeep(dataFailed);
    if (activeTab === '1') {
      listSuccessTemp = listSuccessTemp.filter(
        (item) => item?.phoneNumber !== dataDelete.phoneNumber,
      );
    } else {
      listErrorsTemp = listErrorsTemp.filter(
        (item) => item?.phoneNumber !== dataDelete.phoneNumber,
      );
    }
    setListErros(listErrorsTemp);
    setOriErros(JSON.stringify(listErrorsTemp));

    setListSuccess(listSuccessTemp);
    setOriSuccess(JSON.stringify(listSuccessTemp));

    dispatch(
      setCancelPackageState({
        dataSuccessCancel: listSuccessTemp ?? [],
        dataFailedCancel: listErrorsTemp ?? [],
      }),
    );
  };

  const columnsFail: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    { name: 'Số thuê bao', fieldName: 'phoneNumber', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'code', isHideSort: true },
    { name: 'Lý do', fieldName: 'message', isHideSort: true },
  ];

  const handleExportTemplate = () => {
    if (Number(activeTab ?? 0) === 1) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `danh_sach_huy_goi_${order?.id ?? ''}.xlsx`;
      const url = '/api/DataPackage/cancel/export-excel-order?type=1';
      exportExcelPost(url, listSuccess).then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      });
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `danh_sach_huy_goi_that_bai_${order?.id ?? ''}.xlsx`;
      const url = `/api/DataPackage/cancel/export-excel-order?type=0`;
      const listErrorsTemp = listErrors.map((callbackfn) => {
        return {
          ...callbackfn,
          errorCode: callbackfn?.code ? callbackfn.code : callbackfn.errorCode ?? '',
          errorMessage: callbackfn?.message ?? '',
        };
      });
      exportExcelPost(url, listErrorsTemp).then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      });
    }
  };

  const handleChangeCosType = () => {
    fetchGetPackageAssigned(form.getFieldValue('cosType'));
    form.setFieldValue('mbfCode', '');
    setOpenModalConfirmChangeCosType(false);
  };

  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='row-button justify-end mb-[1.5rem]'>
          <Button
            type='primary'
            disabled={statusPackage === OrderStatusEnum.Uncompleted ? false : true}
            onClick={handleCancel}
          >
            Retry
          </Button>
          <Button
            type='primary'
            disabled={statusPackage === OrderStatusEnum.New && id ? false : true}
            onClick={() => setOpenModalConfirmCancel(true)}
          >
            Huỷ gói
          </Button>
          <Button
            disabled={statusPackage === OrderStatusEnum.New && id ? false : true}
            onClick={() => setOpenModalConfirmDelete(true)}
            danger
            type='primary'
          >
            Xóa
          </Button>
          <Button
            disabled={statusPackage === OrderStatusEnum.New && id ? false : true}
            onClick={() => setStatusPackage(OrderStatusEnum.EDIT)}
          >
            Sửa
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            onClick={async () => {
              !id && (await form.validateFields(['mbfCode', 'phone_number']));
              if (listSuccess.length > 0) {
                handleSubmit();
              } else {
                message.error('Danh sách Huỷ gói rỗng');
              }
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
              }, 3000);
            }}
            disabled={
              statusPackage === OrderStatusEnum.EDIT ||
                (statusPackage === OrderStatusEnum.New && !id)
                ? false
                : true
            }
          >
            Lưu
          </Button>
        </div>
      </div>
      <BoxWithLabel title='Thông tin đơn hàng' classNames='mb-[1.5rem]'>
        <Form form={form}>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item labelCol={{ span: 12 }} label='Mã đơn' name={'id'}>
                {form.getFieldValue('id')}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} label='Loại đơn hàng' name={'type'}>
                Huỷ gói
              </Form.Item>
              <Form.Item
                label='Loại gói'
                labelCol={{ span: 12 }}
                rules={[{ required: true, message: 'Vui lòng chọn loại gói' }]}
                name='cosType'
              >
                <Select
                  showSearch
                  placeholder='Vui lòng chọn'
                  options={[
                    {
                      value: '0',
                      label: 'Add on',
                    },
                    {
                      value: '1',
                      label: 'Nền',
                    },
                    {
                      value: '2',
                      label: 'Addon Ip data',
                    },
                    {
                      value: '3',
                      label: 'Đệm',
                    },
                  ]}
                  disabled={
                    (statusPackage === OrderStatusEnum.New && !id) ||
                      statusPackage === OrderStatusEnum.EDIT
                      ? false
                      : true
                  }
                  onSelect={(value) => console.log(value)}
                  onChange={(value) => {
                    if (value) {
                      if (!id) {
                        fetchGetPackageAssigned(value);
                        form.setFieldValue('mbfCode', '');
                      } else {
                        setOpenModalConfirmChangeCosType(true);
                      }
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Mã gói'
                labelCol={{ span: 12 }}
                rules={[{ required: true, message: 'Vui lòng chọn gói' }]}
                name='mbfCode'
              >
                <Select
                  showSearch
                  placeholder='Vui lòng chọn'
                  options={dataPackage}
                  disabled={
                    (statusPackage === OrderStatusEnum.New && !id) ||
                      statusPackage === OrderStatusEnum.EDIT
                      ? false
                      : true
                  }
                  onChange={() => {
                    if (listSuccess.length > 0 || listSuccess.length > 0) {
                      if (!id) {
                        handleChangeMbfCode();
                      } else {
                        setOpenModalConfirmChangeMbfCode(true);
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Tổng cộng' labelCol={{ span: 12 }} name='countSerial'>
                {form.getFieldValue('countSerial')}
              </Form.Item>
              <Form.Item label='Thành công' labelCol={{ span: 12 }} name='quantitySuccess'>
                {form.getFieldValue('quantitySuccess')}
              </Form.Item>
              <Form.Item label='Thất bại' labelCol={{ span: 12 }} name='quantityFailed'>
                {form.getFieldValue('quantityFailed')}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item></Form.Item>
              <Form.Item></Form.Item>
              <Form.Item
                label='Trạng thái'
                labelCol={{ span: 12 }}
                initialValue={''}
                name='statusString'
              >
                {form.getFieldValue('statusString')}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Người tạo' labelCol={{ span: 12 }} name='createdBy'>
                {form.getFieldValue('createdBy')}
              </Form.Item>
              <Form.Item
                label='Thời gian tạo'
                labelCol={{ span: 12 }}
                name='createdAt'
                valuePropName={
                  form.getFieldValue('createdAt')
                    ? formatDateTime(form.getFieldValue('createdAt'))
                    : ''
                }
              >
                {form.getFieldValue('createdAt')
                  ? formatDateTime(form.getFieldValue('createdAt'))
                  : ''}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Người cập nhật gần nhất</div>}
                labelCol={{ span: 12 }}
                name='updatedBy'
              >
                {form.getFieldValue('updatedBy')}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Ngày cập nhật gần nhất</div>}
                labelCol={{ span: 12 }}
                name='updatedAt'
              >
                {form.getFieldValue('updatedAt')
                  ? new Date(form.getFieldValue('updatedAt')).getTime() ===
                    new Date(form.getFieldValue('createdAt')).getTime()
                    ? ''
                    : formatDateTime(form.getFieldValue('updatedAt'))
                  : ''}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className='mb-[1rem]'>
            <Col span={6}>
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setRadio(e.target.value);
                }}
                disabled={
                  statusPackage === OrderStatusEnum.EDIT ||
                    (statusPackage === OrderStatusEnum.New && !id)
                    ? false
                    : true
                }
                value={radio}
              >
                <Radio value={1}>Nhập STB</Radio>
                <Radio value={2}>Import file</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={16}>
            {radio === 1 ? (
              <Fragment>
                <Col span={8}>
                  <Form.Item
                    labelCol={{ span: 12 }}
                    name='phone_number'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số thuê bao',
                      },
                      {
                        min: 10,
                        message: 'Vui lòng nhập đủ số thuê bao',
                      },
                    ]}
                  >
                    <Input
                      type='number'
                      maxLength={11}
                      onChange={(e) => {
                        if (e.target.value.length <= 11) {
                          form.setFieldValue('phone_number', e.target.value);
                        } else {
                          form.setFieldValue('phone_number', e.target.value.substring(0, 11));
                        }
                        e.preventDefault();
                      }}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '-' ||
                            evt.key === '.' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            evt.preventDefault();
                          }
                        }
                      }}
                      disabled={
                        statusPackage === OrderStatusEnum.EDIT ||
                          (statusPackage === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    form
                      .validateFields(['mbfCode', 'phone_number'])
                      .then(() => {
                        if (radio === 1) {
                          addPhoneNumber(form.getFieldValue('phone_number'));
                        } else {
                          setOpenModalConfirm(true);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                  disabled={
                    statusPackage === OrderStatusEnum.EDIT ||
                      (statusPackage === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                >
                  Thêm
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                {!fileImport && order?.fileUrl && (
                  <Col>
                    <a href={order?.fileUrl} download>
                      <Button type='link'>{order?.fileName || 'Excel file'}</Button>
                    </a>
                  </Col>
                )}
                <Upload
                  disabled={
                    statusPackage === OrderStatusEnum.EDIT ||
                      (statusPackage === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                  {...props}
                  fileList={fileImport ? [fileImport] : []}
                >
                  <Button
                    disabled={
                      statusPackage === OrderStatusEnum.EDIT ||
                        (statusPackage === OrderStatusEnum.New && !id)
                        ? false
                        : true
                    }
                  >
                    Chọn file
                  </Button>
                </Upload>
                <Button
                  disabled={
                    (statusPackage === OrderStatusEnum.EDIT ||
                      (statusPackage === OrderStatusEnum.New && !id)) &&
                      fileImport
                      ? false
                      : true
                  }
                  className='mx-[6px] w-[120px]'
                  onClick={() => {
                    if (id) {
                      setOpenModalConfirm(true);
                    } else {
                      handleImport(fileImport);
                    }
                  }}
                >
                  Import
                </Button>
                <Button className='ml-2'>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/api/Provider/export-excel-template?type=6`}
                  >
                    Export template
                  </a>
                </Button>
              </Fragment>
            )}
          </Row>
        </Form>
      </BoxWithLabel>
      <BoxWithLabel title='Danh sách thuê bao huỷ gói'>
        {/* <Tabs type='card' items={items} /> */}
        <Tabs
          tabBarExtraContent={operations}
          defaultActiveKey='1'
          type='card'
          size='middle'
          onTabClick={(activeKey: string) => {
            setActiveTab(activeKey);
            const data = params;
            data.pageIndex = 1;
            data.pageSize = 10;
            setSearchParams(data);
          }}
          items={new Array(2).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
              label: id === '1' ? `Danh sách đúng` : 'Danh sách sai',
              key: id,
              children: (
                <TableContent
                  scroll={{
                    x: 'max-content',
                  }}
                  locale={{ emptyText: 'Không tìm thấy kết quả' }}
                  totalRecord={id === '1' ? listSuccess?.length : listErrors?.length}
                  dataSource={
                    id === '1' ? filterDataSource(listSuccess) : filterDataSource(listErrors)
                  }
                  columns={id === '1' ? columns : columnsFail}
                  onChange={(pagination) => {
                    console.log('pagination', pagination);
                  }}
                  onPageChange={(pageNumber, pageSize) => {
                    console.log('pageNumber', pageNumber);
                    console.log('pageSize', pageSize);
                  }}
                />
              ),
            };
          })}
        />
      </BoxWithLabel>
      <ModalConfirm
        open={openModalConfirm}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Bạn chắc chưa?</div>
            <p>Sau khi thêm sẽ xóa dữ liệu trước đó</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={() => {
                  // Import serial
                  if (radio === 1) {
                    form.validateFields(['mbfCode', 'phone_number']).then(() => {
                      addPhoneNumber(form.getFieldValue('phone_number'));
                    });
                  }
                  //import excel
                  else {
                    handleImport(fileImport);
                  }
                }}
                className='h-full w-full'
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirm(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmDelete}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xoá đơn hàng?</div>
            <p>Bạn có chắc chắn muốn xoá đơn hàng Huỷ gói này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleDeletePackage} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmDelete(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmCancel}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Huỷ đơn hàng?</div>
            <p>Bạn có muốn thực hiện đăng ký huỷ gói?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleCancel} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  form.setFieldValue('mbfCode', order?.mbfCode);
                  setOpenModalConfirmCancel(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmChangeMbfCode}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Đổi mã gói?</div>
            <p>Bạn có chắc chắn muốn đổi mã gói đơn hàng này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={handleChangeMbfCode}
                className='h-full w-full'
                disabled={isLoadingModal}
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmChangeMbfCode(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmChangeCosType}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Đổi loại gói?</div>
            <p>Bạn có chắc chắn muốn đổi loại gói đơn hàng này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={handleChangeCosType}
                className='h-full w-full'
                disabled={isLoadingModal}
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  form.setFieldValue('cosType', cosTypeTemp ?? '1');
                  setOpenModalConfirmChangeCosType(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmChangeFail}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Gói không hợp lệ?</div>
            <p>Bạn có muốn đổi gói khác cho số thuê bao này không?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                className='h-full w-full'
                disabled={isLoadingModal}
                onClick={() => {
                  setOpenModalConfirmChangeFail(false);
                }}
              >
                XÁC NHẬN ĐỔI
              </Button>
              <Button className='h-full w-full' onClick={handleChangeMbfFail}>
                Huỷ
              </Button>
            </div>
          </div>
        }
      />
    </Fragment>
  );
}

export default CreateCancelPackagePage;
