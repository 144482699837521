/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Tabs,
  Upload,
  UploadProps,
} from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { cloneDeep } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IListColumn } from 'src/@types/search-params';
import { getBalance } from 'src/api/localpay';
import { uploadFilePackageAssigned } from 'src/api/package_assigned';
import {
  cancelTopupPackage,
  checkImportExcelTopup,
  checkInputPhoneTopup,
  confirmTopupPackage,
  createTopupPackage,
  deleteTopup,
  getDetailTopup,
  getTopupName,
  updateTopupPackage,
} from 'src/api/register_and_cancel_package';
import BoxWithLabel from 'src/components/BoxWithLabel/BoxWithLabel';
import TableContent from 'src/components/CTable';
import ModalConfirm from 'src/components/modals/ModalComfirm';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { setGlobalState } from 'src/redux/slice/globalSlice';
import { setModalState } from 'src/redux/slice/modal.store';
import { setCancelPackageState } from 'src/redux/slice/registerAndCancelPackage';
import { orderStatus } from 'src/shared/common/package_assigned';
import { SimStatusEnum } from 'src/shared/enum/change_sim';
import { OrderStatusEnum } from 'src/shared/enum/package_assigned';
import {
  ICheckInputSerialParamsRequest,
  ICheckInputTopupParams,
  IOverFlowOrderDetailResponse,
} from 'src/shared/interface/package_assigned';
import {
  DiscountTopup,
  IRegisterPackage,
  PackageUpdate,
} from 'src/shared/interface/register_and_cancel';
import { exportExcelPost, formatDateTime, formatMoney, parseQueryString } from 'src/utils';
import ModalCheckEsimExpired from '../../changeSim/ModalCheckEsimExpired';

function CreateTopupPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const watchCode = useWatch('topupName', form);
  const location = useLocation();
  const { id } = parseQueryString(location.search);
  const params = parseQueryString(location.search);
  const [searchParams, setSearchParams] = useSearchParams();
  const { refresh, order, openTabs } = useAppSelector((state) => state.global);
  const { dataSuccessTopup: dataSuccess, dataFailedTopup: dataFailed } = useAppSelector(
    (state) => state.registerAndCancel,
  );

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [openModalConfirmTopup, setOpenModalConfirmTopup] = useState(false);
  const [openModalConfirmChange, setOpenModalConfirmChange] = useState(false);
  const [openModalConfirmCancel, setOpenModalConfirmCancel] = useState(false);
  const [prevValue, setPrevValue] = useState<any>(null);
  const [tempChange, setTempChange] = useState<any>();
  const [isOpenModalCheckEsimExpired, setIsOpenModalCheckEsimExpired] = useState(false);

  const [listEsimExpired, setListEsimExpired] = useState([]);
  const [radio, setRadio] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [statusPackage, setStatusPackage] = useState<string>(OrderStatusEnum.New);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [listEsimValid, setListEsimValid] = useState([]);
  const [valueSearch, setValueSearch] = useState('');
  const [selectStatus, setSelectStatus] = useState<any>(null);
  const [activeTab, setActiveTab] = useState('1');
  const [oriErros, setOriErros] = useState('');
  const [oriSuccess, setOriSuccess] = useState('');
  const [listErrors, setListErros] = useState<IOverFlowOrderDetailResponse[]>(
    location.search ? [] : dataFailed,
  );
  const [listSuccess, setListSuccess] = useState<IOverFlowOrderDetailResponse[]>(
    location.search ? [] : dataSuccess,
  );
  const [fileInfo, setFileInfo] = useState<any>(null);
  const [fileImport, setFileImport] = useState<any>(null);
  const [pathFile, setPathFile] = useState<any>(null);
  const [dataTopupName, setDataTopupName] = useState<DiscountTopup[]>([]);
  const [tempTopupLine, setTempTopupLine] = useState<string[]>([]);

  useEffect(() => {
    const fetchBalance = async () => {
      const balanceRes = await getBalance();
      if (balanceRes) {
        dispatch(setGlobalState({ balance: balanceRes.balance }));
      }
    };
    dispatch(
      setCancelPackageState({
        dataSuccessTopup: id ? dataSuccess : [],
        dataFailedTopup: id ? dataFailed : [],
      }),
    );
    dispatch(setGlobalState({ order: undefined }));
    fetchBalance();
    return () => {
      dispatch(
        setCancelPackageState({
          dataSuccessTopup: id ? dataSuccess : [],
          dataFailedTopup: id ? dataFailed : [],
        }),
      );
    };
  }, []);

  const fetchGetTopupName = async () => {
    await getTopupName().then((res: any) => {
      if (res) {
        const dataPackage: DiscountTopup[] = [];
        res?.map((item: any) => {
          dataPackage?.push({
            ...item,
            label: item.name,
            value: item.name,
          });
        });
        setDataTopupName(dataPackage);
      }
    });
  };
  useEffect(() => {
    fetchGetTopupName();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (statusPackage === OrderStatusEnum.Processing) {
        fetchDetailOrder();
      }
    }, 30000);
    if (statusPackage !== OrderStatusEnum.Processing) {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [statusPackage]);

  const fetchDetailOrder = async () => {
    const res = await getDetailTopup(id);
    console.log(res);
    
    if (res) {
      if (res.fileUrl) {
        setRadio(2);
        setPathFile(res.fileUrl);
      }
      const totalPrice =
        res.overFlowOrderDetailResponses?.reduce((item, { price }) => item + (price ?? 0), 0) ?? 0;
      const status = orderStatus.find((item) => item.value === res.statusString);
      form.setFieldsValue({
        ...res,
        topupName: res.topupName ?? [],
        statusString: status?.label ? status.label : '',
        totalPrice: totalPrice ?? '0',
        discountPrice: totalPrice - (res?.toTalAmount ?? 0) ?? '0',
      });
      setPrevValue(res.topupName ?? []);
      dispatch(
        setGlobalState({
          order: { ...res, discountPrice: totalPrice - (res?.toTalAmount ?? 0) ?? '0' },
        }),
      );

      let listCheckSuccess: IOverFlowOrderDetailResponse[] = [];
      const listCheckFail: IOverFlowOrderDetailResponse[] = [];
      const listEsimValidTmp: any = [];
      if (res.statusString !== 'New') {
        listCheckSuccess = res.overFlowOrderDetailResponses ? res.overFlowOrderDetailResponses : [];
      } else {
        res.overFlowOrderDetailResponses?.map((item: any, index: number) => {
          if (res.statusString === OrderStatusEnum.New) {
            listCheckSuccess.push({ ...item, key: index });
          } else if (item.statusString === OrderStatusEnum.Failed) {
            if (item.id) {
              const errorItem = {
                code: item.errorCode,
                message: item.errorMessage,
                serial: item.serial,
              };
              listCheckFail.push(errorItem);
            } else {
              listCheckFail.push(item);
            }
          } else {
            listCheckSuccess.push(item);
          }

          if (item?.statusString === 'New') {
            listEsimValidTmp.push(item);
          }
        });
      }
      setListSuccess(listCheckSuccess);
      setListErros([]);
      setOriSuccess(JSON.stringify(res.overFlowOrderDetailResponses));
      dispatch(setCancelPackageState({ dataSuccessTopup: id ? listCheckSuccess : [] }));
      if (res.statusString !== OrderStatusEnum.New) {
        dispatch(setCancelPackageState({ dataFailedTopup: id ? listCheckFail : [] }));
      }
      setStatusPackage(res.statusString ? res.statusString.toString() : '');
      setListEsimValid(listEsimValidTmp);
    }
  };

  useEffect(() => {
    if (id) {
      fetchDetailOrder();
    } else {
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setCancelPackageState({ dataSuccessTopup: [], dataFailedTopup: [] }));
      form.resetFields();
    }

    return () => {
      setStatusPackage(OrderStatusEnum.New);
      dispatch(setGlobalState({ order: undefined }));
      dispatch(setCancelPackageState({ dataSuccessTopup: [], dataFailedTopup: [] }));
    };
  }, [id, refresh]);

  const editTopupNameLine = (phoneNumber: number, topupName?: string[]) => {
    if (form.getFieldValue('topupName')) {
      const params: ICheckInputTopupParams = {
        orderId: id ? id : undefined,
        topupProviders: [
          {
            topupName: topupName,
            orderId: id ? id : undefined,
            phoneNumber: phoneNumber,
          },
        ],
      };
      checkInputPhoneTopup(params)
        .then((res) => {
          if (res) {
            const assignSuccess = cloneDeep(dataSuccess);
            const dataIndex = assignSuccess.findIndex(
              (predicate) => predicate.phoneNumber === phoneNumber,
            );

            if (res?.phoneNumberSuccess.length) {
              assignSuccess[dataIndex].topupName = res?.phoneNumberSuccess[0].topupName;
              assignSuccess[dataIndex].price = res?.phoneNumberSuccess[0].price;
              setListSuccess(assignSuccess);
              setOriSuccess(JSON.stringify(assignSuccess));

              dispatch(
                setCancelPackageState({
                  dataSuccessTopup: assignSuccess,
                }),
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.warning(`Vui lòng chọn ${'mệnh giá'}!`);
    }
  };
  const addPhoneNumber = (phoneNumber: number) => {
    if (form.getFieldValue('topupName')) {
      const params: ICheckInputTopupParams = {
        orderId: id ? id : undefined,
        topupProviders: [
          {
            topupName: form.getFieldValue('topupName'),
            orderId: id ? id : undefined,
            phoneNumber: phoneNumber,
          },
        ],
      };
      checkInputPhoneTopup(params)
        .then((res) => {
          if (res) {
            const newDataSuccess: IOverFlowOrderDetailResponse[] = [...dataSuccess];
            const newDataFailed: IOverFlowOrderDetailResponse[] = [...dataFailed];
            if (pathFile) {
              setFileImport(null);
              setPathFile('');
              setFileInfo(null);
              setListSuccess([]);
              setListErros([]);
              setOriSuccess('');
              setOriErros('');
            } else {
              //Xử lí duplicate
              [...res.phoneNumberSuccess].forEach((item) => {
                //Trường hợp không trùng bất cứ thằng nào.
                if (!dataSuccess.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                  newDataSuccess.push(item);
                } else {
                  const findIndex = dataSuccess.findIndex(
                    (predicate) => predicate.phoneNumber === item.phoneNumber,
                  );
                  newDataSuccess[findIndex] = item;
                }
              });
              [...res.phoneNumberError].forEach((item) => {
                //Trường hợp không trùng bất cứ thằng nào.
                if (!dataFailed.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                  newDataFailed.unshift(item);
                } else {
                  const findIndex = dataFailed.findIndex(
                    (predicate) => predicate.phoneNumber === item.phoneNumber,
                  );
                  newDataFailed[findIndex] = item;
                }
              });
            }

            setListSuccess(pathFile ? res.phoneNumberSuccess : newDataSuccess);
            setListErros(pathFile ? res.phoneNumberError : newDataFailed);
            setOriSuccess(
              pathFile ? JSON.stringify(res.phoneNumberSuccess) : JSON.stringify(newDataSuccess),
            );
            setOriErros(
              pathFile ? JSON.stringify(res.phoneNumberError) : JSON.stringify(newDataFailed),
            );

            dispatch(
              setCancelPackageState({
                dataFailedTopup: pathFile ? res.phoneNumberError : newDataFailed,
                dataSuccessTopup: pathFile ? res.phoneNumberSuccess : newDataSuccess,
              }),
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.warning(`Vui lòng chọn ${'mệnh giá'}!`);
    }
  };

  const handleImport = async () => {
    if (radio === 2) {
      if (form.getFieldValue('topupName') && fileImport) {
        const res = await checkImportExcelTopup({
          excelFile: fileImport?.originFileObj ? fileImport.originFileObj : fileImport,
          topupName: form.getFieldValue('topupName') ?? '',
          orderId: id ? id : undefined,
        });
        if (res) {
          const unique = res.phoneNumberError.filter(
            (obj, index) =>
              res.phoneNumberError.findIndex((item) => item.phoneNumber === obj.phoneNumber) ===
              index,
          );
          setListSuccess(res.phoneNumberSuccess);
          setListErros(unique);
          setOriSuccess(JSON.stringify(res.phoneNumberSuccess));
          setOriErros(JSON.stringify(unique));
          dispatch(
            setCancelPackageState({
              dataSuccessTopup: res.phoneNumberSuccess ?? [],
              dataFailedTopup: res.phoneNumberError ?? [],
            }),
          );
        }
        setOpenModalConfirm(false);
      } else {
        message.warning(`Vui lòng chọn mệnh giá!`);
      }
    } else {
      setOpenModalConfirm(true); // Hiển thị modal xác nhận
    }
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      const isPNG =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (!isPNG) {
        message.error(`${file.name} không đúng định dạng`);
      }
      return isPNG;
    },
    onChange: ({ file }) => {
      if (file.status === 'removed') {
        setFileImport(null);
      } else {
        file.status = 'done';
        setFileImport(file);
      }
    },
    customRequest: async (info) => {
      if (watchCode) {
        uploadFilePackageAssigned(info.file).then((res) => {
          setPathFile(res);
          console.log('fileInfo', fileInfo);
        });
      } else {
        message.error(`Vui lòng chọn mệnh giá`);
      }
    },
  };

  const handleSubmit = async () => {
    if (statusPackage === OrderStatusEnum.New) {
      if ([...listSuccess].length === 0) {
        message.error('Vui lòng import số!');
      } else {
        try {
          let getFileUrl = '';
          if (radio === 2 && fileImport) {
            getFileUrl = await uploadFilePackageAssigned(
              fileImport?.originFileObj ? fileImport.originFileObj : fileImport,
            );
          }
          const listPhoneNumber = dataSuccess.map((item) => {
            return {
              phoneNumber: item.phoneNumber ?? '',
              topupName: item.topupName ?? [],
            };
          });
          const params: IRegisterPackage = {
            topupName: form.getFieldValue('topupName') ?? [],
            data: listPhoneNumber,
            fileUrl: getFileUrl ? getFileUrl : undefined,
            fileName: fileImport ? fileImport.name : undefined,
          };

          const res = await createTopupPackage(params);
          if (res) {
            message.success('Tạo đơn hàng thành công!');
          }
          const newPanes = openTabs.filter((pane) => pane.key !== 'topup/create');

          dispatch(
            setGlobalState({
              activeKeyMenu: 'topup/detail' + '?id=' + res.id,
              refresh: !refresh,
            }),
          );
          if (!newPanes.find((tab) => tab.key === 'topup/detail' + '?id=' + res.id)) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Xem chi tiết Nạp tiền ' + res.id,
                    children: '',
                    key: 'topup/detail' + '?id=' + res.id,
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/topup/detail?id=${res.id}`);
        } catch (error) {
          console.log(error);

          // navigate(`/management/register-and-cancel-package/register/list`);
        }
      }
    }
    if (statusPackage === OrderStatusEnum.EDIT) {
      if (form.getFieldValue('topupName') && listSuccess.length > 0) {
        let getFileUrl = '';

        if (radio === 2 && fileImport) {
          getFileUrl = await uploadFilePackageAssigned(
            fileImport?.originFileObj ? fileImport.originFileObj : fileImport,
          );
        }
        const listSerial = dataSuccess.map((item) => {
          return {
            phoneNumber: item.phoneNumber ?? '',
            topupName: item.topupName ?? [],
          };
        });
        const data: PackageUpdate = {
          orderId: id,
          data: listSerial,
          topupName: form.getFieldValue('topupName'),
          fileUrl: getFileUrl ? getFileUrl : undefined,
          fileName: fileImport ? fileImport.name : undefined,
        };
        await updateTopupPackage(data);
        dispatch(setGlobalState({ refresh: !refresh }));
        setStatusPackage(OrderStatusEnum.New);
      } else {
        message.error('Vui lòng nhập danh sách thuê bao nạp tiền!');
      }
    }
  };

  const handleDeletePackage = async () => {
    if (id) {
      try {
        const res = await deleteTopup(id);
        if (res) {
          message.success('Xoá đơn hàng thành công!');
          const newPanes = openTabs.filter((pane) => pane.key !== 'topup/detail');
          dispatch(
            setGlobalState({
              activeKeyMenu: 'topup/list',
            }),
          );
          if (!newPanes.find((tab) => tab.key === 'topup/list')) {
            dispatch(
              setGlobalState({
                openTabs: [
                  ...newPanes,
                  {
                    label: 'Đơn hàng Nạp tiền',
                    children: '',
                    key: 'topup/list',
                    closable: true,
                  },
                ],
              }),
            );
          }
          navigate(`/management/topup/list`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleTopup = async () => {
    try {
      const res = await confirmTopupPackage({ orderId: id });
      if (res) {
        setOpenModalConfirmTopup(false);
        dispatch(setGlobalState({ refresh: !refresh }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModalConfirmTopup(false);
    }
  };
  const handleCancelTopup = async () => {
    try {
      const res = await cancelTopupPackage(id);
      if (res) {
        dispatch(setGlobalState({ refresh: !refresh }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenModalConfirmCancel(false);
    }
  };

  const handleSearchItem = () => {
    if (!valueSearch && !selectStatus) {
      if (activeTab === '2' && oriErros) {
        try {
          setListErros(JSON.parse(oriErros));
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          setListSuccess(JSON.parse(oriSuccess));
        } catch (error) {
          console.log(error);
        }
      }
    } else if (activeTab === '2' && oriErros) {
      const dataError = JSON.parse(oriErros);
      console.log('dataError', dataError);

      setListErros(
        dataError.filter(function (item: any) {
          if (
            valueSearch &&
            (item.phoneNumber?.includes(valueSearch) ||
              item.userName?.includes(valueSearch) ||
              item.serial?.includes(valueSearch) ||
              item.serialNew?.includes(valueSearch))
          ) {
            return true;
          }
          if (selectStatus) {
            console.log('selectStatus');
          } else {
            return false;
          }
        }),
      );
    } else if (activeTab === '1' && oriSuccess) {
      const dataSuccess = JSON.parse(oriSuccess);

      setListSuccess(
        dataSuccess.filter(function (item: any) {
          if (
            valueSearch &&
            item.phoneNumber.includes(valueSearch) &&
            selectStatus &&
            selectStatus === item.statusString
          ) {
            return true;
          } else if (valueSearch && item.phoneNumber.includes(valueSearch) && !selectStatus) {
            return true;
          } else if (selectStatus && selectStatus === item.statusString && !valueSearch) {
            return true;
          } else if (!selectStatus && !valueSearch) {
            return true;
          } else {
            return false;
          }
        }),
      );
    }
    setSearchParams({
      ...searchParams,
      ...params,
      pageIndex: 1,
    });
  };

  const operations = (
    <div>
      <Input
        placeholder='Số thuê bao, tên thuê bao'
        onChange={(e) => {
          setValueSearch(e.target.value);
        }}
        style={{ width: 300 }}
        allowClear
      />

      <span className='mx-[12px]'>Trạng thái </span>
      <Select
        onSelect={(e) => {
          setSelectStatus(e);
        }}
        className='w-[120px]'
        placeholder='Vui lòng chọn'
        options={[
          {
            value: '',
            label: 'Tất cả',
          },
          {
            value: SimStatusEnum.Success,
            label: 'Success',
          },
          {
            value: SimStatusEnum.Failed,
            label: 'Failed',
          },
          {
            value: SimStatusEnum.Pending,
            label: 'Pending',
          },
        ]}
        defaultValue={''}
      />
      <Button className='w-[120px] mx-[12px] ' onClick={() => handleSearchItem()}>
        Tìm kiếm
      </Button>
      <Button className='w-[120px] mr-[12px]' onClick={() => handleExportTemplate()}>
        Export
      </Button>
    </div>
  );

  const filterDataSource = (data: any) => {
    let cloneData = JSON.parse(JSON.stringify(data));
    const count = activeTab === '1' ? listSuccess.length : listErrors.length;
    if (
      count == params.pageSize * (params.pageIndex > 1 ? params.pageIndex - 1 : 1) &&
      params.pageIndex > 1
    ) {
      const data = params;
      data.pageIndex = data.pageIndex > 1 ? data.pageIndex - 1 : 1;
      data.pageSize = 10;
      setSearchParams(data);
    }

    cloneData = cloneData.filter((_error: any, index: any) => {
      // debugger
      const pageIndex = params.pageIndex ? parseInt(params.pageIndex) : 1;
      const pageSize = params.pageSize ? parseInt(params.pageSize) : 10;
      if (pageIndex === 1 && index < pageIndex * pageSize) {
        return true;
      } else if (index < pageIndex * pageSize && index >= (pageIndex - 1) * pageSize) {
        return true;
      } else return false;
    });

    return cloneData;
  };

  const columns: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20 },
    { name: 'Số thuê bao', fieldName: 'phoneNumber', isHideSort: true },
    { name: 'Tên thuê bao', fieldName: 'userName', isHideSort: true },
    {
      name: 'Mệnh giá',
      fieldName: 'topupName',
      isHideSort: true,
      renderSpecialCard: (item: IOverFlowOrderDetailResponse) => {
        return (
          <SelectItemTable
            dataTopupName={dataTopupName}
            item={item}
            statusPackage={statusPackage}
            id={id}
            editTopupNameLine={editTopupNameLine}
            onChange={(e:string[]) => {
              // const assignSuccess = cloneDeep(dataSuccess);
              // const dataIndex = assignSuccess.findIndex(
              //   (predicate) => predicate.phoneNumber === item.phoneNumber,
              // );
              // assignSuccess[dataIndex].topupName = e;
              // setListSuccess(assignSuccess);
              // setOriSuccess(JSON.stringify(assignSuccess));

              // dispatch(
              //   setCancelPackageState({
              //     dataSuccessTopup: assignSuccess,
              //   }),
              // );
              setTempTopupLine(e);
            }}
            onDropdownVisibleChange={(open: boolean, tempLine: string[]) => {
              if (!open) {
                editTopupNameLine(item.phoneNumber, tempLine);
              }
            }}
          />
          // <Select
          //   showSearch
          //   placeholder='Vui lòng chọn'
          //   options={dataTopupName}
          //   value={item.topupName}
          //   mode='multiple'
          //   disabled={
          //     statusPackage === OrderStatusEnum.EDIT ||
          //     (statusPackage === OrderStatusEnum.New && !id)
          //       ? false
          //       : true
          //   }
          //   onDropdownVisibleChange={(open) => {
          //     console.log(open);

          //     if (!open) {
          //       editTopupNameLine(item.phoneNumber, tempTopupLine);
          //     }
          //   }}
          //   onSelect={() => {

          //   }}
          //   onChange={(e) => {
          //     const assignSuccess = cloneDeep(dataSuccess);
          //     const dataIndex = assignSuccess.findIndex(
          //       (predicate) => predicate.phoneNumber === item.phoneNumber,
          //     );
          //     assignSuccess[dataIndex].topupName = e;
          //     setListSuccess(assignSuccess);
          //     setOriSuccess(JSON.stringify(assignSuccess));

          //     dispatch(
          //       setCancelPackageState({
          //         dataSuccessTopup: assignSuccess,
          //       }),
          //     );
          //     setTempTopupLine(e);
          //   }}
          // />
        );
      },
    },
    {
      name: 'Thành tiền',
      fieldName: 'price',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{record.price ? formatMoney(record.price, 'đ') : ''}</span>;
      },
    },
    {
      name: 'Nạp thành công',
      fieldName: 'topupSuccessPrice',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{record.price ? formatMoney(record.topupSuccessPrice, 'đ') : ''}</span>;
      },
    },
    {
      name: 'Nạp thất bại',
      fieldName: 'topupFailedPrice',
      isHideSort: true,
      renderSpecialCard: (record) => {
        return <span>{record.price ? formatMoney(record.topupFailedPrice, 'đ') : ''}</span>;
      },
    },
    { name: 'Trạng thái', fieldName: 'statusString', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Mô tả lỗi', fieldName: 'errorMessage', isHideSort: true },
    {
      name: 'Thao tác',
      renderSpecialCard: (item) => (
        <button
          className={`underline whitespace-nowrap bg-transparent border-transparent text-blue-500 font-semibold ${
            statusPackage === OrderStatusEnum.EDIT ||
            (statusPackage === OrderStatusEnum.New && !order?.id)
              ? 'cursor-pointer'
              : 'opacity-30'
          }`}
          disabled={
            statusPackage === OrderStatusEnum.EDIT ||
            (statusPackage === OrderStatusEnum.New && !order?.id)
              ? false
              : true
          }
          onClick={() => handleDeletePhoneNumber(item)}
        >
          Xóa
        </button>
      ),
      isHideSort: true,
    },
  ];

  const handleDeletePhoneNumber = (dataDelete: IOverFlowOrderDetailResponse) => {
    let listSuccessTemp = cloneDeep(dataSuccess);
    let listErrorsTemp = cloneDeep(dataFailed);
    if (activeTab === '1') {
      listSuccessTemp = listSuccessTemp.filter(
        (item) => item?.phoneNumber !== dataDelete.phoneNumber,
      );
    } else {
      listErrorsTemp = listErrorsTemp.filter(
        (item) => item?.phoneNumber !== dataDelete.phoneNumber,
      );
    }
    setListErros(listErrorsTemp);
    setOriErros(JSON.stringify(listErrorsTemp));

    setListSuccess(listSuccessTemp);
    setOriSuccess(JSON.stringify(listSuccessTemp));

    dispatch(
      setCancelPackageState({
        dataSuccessTopup: listSuccessTemp ?? [],
        dataFailedTopup: listErrorsTemp ?? [],
      }),
    );
  };

  const columnsFail: IListColumn[] = [
    { name: 'STT', fieldName: 'index', width: 20, isHideSort: true },
    { name: 'Số thuê bao', fieldName: 'phoneNumber', isHideSort: true },
    { name: 'Mã lỗi', fieldName: 'errorCode', isHideSort: true },
    { name: 'Lý do', fieldName: 'message', isHideSort: true },
  ];

  const handleExportTemplate = () => {
    if (Number(activeTab ?? 0) === 1) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `danh_sach_nap_tien_thanh_cong_${order?.id ?? ''}.xlsx`;
      const url = '/api/topup-provider/export-detail-topup?type=1';
      exportExcelPost(url, listSuccess).then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      });
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `danh_sach_nap_tien_that_bai_${order?.id ?? ''}.xlsx`;
      const url = '/api/topup-provider/export-detail-topup?type=0';

      const listErrorsTemp = listErrors.map((callbackfn) => {
        return {
          ...callbackfn,
          errorCode: callbackfn?.code ? callbackfn.code : callbackfn.errorCode ?? '',
          errorMessage: callbackfn?.message ?? '',
        };
      });
      exportExcelPost(url, listErrorsTemp).then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.ms-excel' }));
        link.click();
      });
    }
  };

  const handlePrePayment = async () => {
    if (id) {
      onClickConfirmEsimExpired();
    } else {
      console.log('error');
    }
  };

  const onClickConfirmEsimExpired = () => {
    dispatch(
      setGlobalState({
        refresh: !refresh,
      }),
    );
    setIsOpenModalCheckEsimExpired(false);
    dispatch(
      setModalState({
        open: true,
        content: 'top-up-package-info',
        width: '50%',
        selectedHistory: undefined,
      }),
    );
  };

  const onCancelEsimExpired = () => {
    setIsOpenModalCheckEsimExpired(false);
    dispatch(
      setGlobalState({
        refresh: !refresh,
      }),
    );
  };

  const handleChangeTopup = () => {
    if (listSuccess.length > 0 || listSuccess.length > 0) {
      if (form.getFieldValue('topupName')) {
        // form.setFieldValue('topupName', tempChange);
        const params: ICheckInputSerialParamsRequest[] = [];
        dataSuccess.forEach((predicate) => {
          params.push({
            topupName: form.getFieldValue('topupName') ?? '',
            orderId: id ? id : undefined,
            phoneNumber: predicate.phoneNumber,
          });
        });
        checkInputPhoneTopup({ orderId: id ? id : undefined, topupProviders: params })
          .then((res) => {
            if (res) {
              const newDataSuccess: IOverFlowOrderDetailResponse[] = [...dataSuccess];
              const newDataFailed: IOverFlowOrderDetailResponse[] = [...dataFailed];
              if (pathFile) {
                setFileImport(null);
                setPathFile('');
                setFileInfo(null);
                setListSuccess([]);
                setListErros([]);
                setOriSuccess('');
                setOriErros('');
                setTempChange('');
              } else {
                //Xử lí duplicate
                [...res.phoneNumberSuccess].forEach((item) => {
                  //Trường hợp không trùng bất cứ thằng nào.
                  if (!dataSuccess.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                    newDataSuccess.push(item);
                  } else {
                    const findIndex = dataSuccess.findIndex(
                      (predicate) => predicate.phoneNumber === item.phoneNumber,
                    );
                    newDataSuccess[findIndex] = item;
                  }
                });
                [...res.phoneNumberError].forEach((item) => {
                  //Trường hợp không trùng bất cứ thằng nào.
                  if (!dataFailed.some((obj) => obj.phoneNumber === item.phoneNumber)) {
                    newDataFailed.unshift(item);
                  } else {
                    const findIndex = dataFailed.findIndex(
                      (predicate) => predicate.phoneNumber === item.phoneNumber,
                    );
                    newDataFailed[findIndex] = item;
                  }
                });
              }

              setListSuccess(pathFile ? res.phoneNumberSuccess : newDataSuccess);
              setListErros(pathFile ? res.phoneNumberError : newDataFailed);
              setOriSuccess(
                pathFile ? JSON.stringify(res.phoneNumberSuccess) : JSON.stringify(newDataSuccess),
              );
              setOriErros(
                pathFile ? JSON.stringify(res.phoneNumberError) : JSON.stringify(newDataFailed),
              );

              dispatch(
                setCancelPackageState({
                  dataFailedTopup: pathFile ? res.phoneNumberError : newDataFailed,
                  dataSuccessTopup: pathFile ? res.phoneNumberSuccess : newDataSuccess,
                }),
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        message.warning(`Vui lòng chọn ${'mệnh giá'}!`);
      }
    }
    setOpenModalConfirmChange(false);
  };

  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='row-button justify-end mb-[1.5rem]'>
          <Button
            type='default'
            disabled={order?.statusString === OrderStatusEnum.PAYMENT_FAIL ? false : true}
            onClick={() => setOpenModalConfirmCancel(true)}
          >
            Huỷ
          </Button>
          {/* <Button
            type='primary'
            disabled={
              order?.statusString === OrderStatusEnum.Uncompleted &&
              !dataSuccess.find((predicate) => predicate.statusString === SimStatusEnum.Pending)
                ? false
                : true
            }
            onClick={handleTopup}
          >
            Retry
          </Button> */}
          {/* <Button
            type='primary'
            disabled={order?.statusString === OrderStatusEnum.Paid ? false : true}
            onClick={() => setOpenModalConfirmTopup(true)}
          >
            Nạp tiền
          </Button> */}
          <Button
            type='primary'
            disabled={
              (statusPackage === OrderStatusEnum.New && listEsimValid?.length > 0) ||
              order?.statusString === OrderStatusEnum.PAYMENT_FAIL
                ? false
                : true
            }
            onClick={() => {
              if (dataSuccess.length > 0) {
                handlePrePayment();
              } else {
                message.error('Danh sách thuê bao nạp tiền rỗng!');
              }
            }}
          >
            Thanh toán
          </Button>
          <Button
            disabled={statusPackage === OrderStatusEnum.New && id ? false : true}
            onClick={() => setOpenModalConfirmDelete(true)}
            danger
            type='primary'
          >
            Xóa
          </Button>
          <Button
            disabled={statusPackage === OrderStatusEnum.New && id ? false : true}
            onClick={() => setStatusPackage(OrderStatusEnum.EDIT)}
          >
            Sửa
          </Button>
          <Button
            type='primary'
            loading={isLoading}
            onClick={async () => {
              !id && (await form.validateFields(['topupName', 'phone_number']));
              if (listSuccess.length > 0) {
                handleSubmit();
              } else {
                message.error('Danh sách Nạp tiền rỗng');
              }
              setIsLoading(true);
              form.setFieldValue('phone_number', '');
              setTimeout(() => {
                setIsLoading(false);
              }, 3000);
            }}
            disabled={
              statusPackage === OrderStatusEnum.EDIT ||
              (statusPackage === OrderStatusEnum.New && !id)
                ? false
                : true
            }
          >
            Lưu
          </Button>
        </div>
      </div>
      <BoxWithLabel title='Thông tin đơn hàng' classNames='mb-[1.5rem]'>
        <Form form={form}>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item labelCol={{ span: 12 }} label='Mã đơn' name={'id'}>
                {form.getFieldValue('id')}
              </Form.Item>
              <Form.Item labelCol={{ span: 12 }} label='Loại đơn hàng' name={'type'}>
                {'Nạp tiền'}
              </Form.Item>
              <Form.Item
                label='Mệnh giá'
                labelCol={{ span: 12 }}
                rules={[{ required: true, message: 'Vui lòng chọn mệnh giá' }]}
                name='topupName'
              >
                <Select
                  showSearch
                  placeholder='Vui lòng chọn'
                  options={dataTopupName}
                  mode='multiple'
                  allowClear={false}
                  removeIcon={<></>}
                  disabled={
                    statusPackage === OrderStatusEnum.EDIT ||
                    (statusPackage === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                  onDropdownVisibleChange={(open) => {
                    if (dataSuccess.length && !open) {
                      setOpenModalConfirmChange(true);
                    }
                  }}
                  onChange={(newValues, options) => {
                    if (dataSuccess.length) {
                      form.setFieldValue('topupName', newValues);
                      setTempChange(prevValue);
                    } else {
                      setPrevValue(newValues);
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Trạng thái'
                labelCol={{ span: 12 }}
                initialValue={''}
                name='statusString'
              >
                {form.getFieldValue('statusString')}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Tổng cộng' labelCol={{ span: 12 }} name='countSerial'>
                {form.getFieldValue('countSerial')}
              </Form.Item>
              <Form.Item label='Thành công' labelCol={{ span: 12 }} name='quantitySuccess'>
                {form.getFieldValue('quantitySuccess')}
              </Form.Item>
              <Form.Item label='Thất bại' labelCol={{ span: 12 }} name='quantityFailed'>
                {form.getFieldValue('quantityFailed')}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label='Tổng tiền' labelCol={{ span: 12 }} name='totalPrice'>
                {form.getFieldValue('totalPrice')
                  ? formatMoney(form.getFieldValue('totalPrice'), 'đ')
                  : '0đ'}
              </Form.Item>
              <Form.Item label='Chiết khấu' labelCol={{ span: 12 }} name='discountPrice'>
                {form.getFieldValue('discountPrice')
                  ? formatMoney(form.getFieldValue('discountPrice'), 'đ')
                  : '0đ'}
              </Form.Item>
              <Form.Item label='Thành tiền' labelCol={{ span: 12 }} name='toTalAmount'>
                {form.getFieldValue('toTalAmount')
                  ? formatMoney(form.getFieldValue('toTalAmount'), 'đ')
                  : '0đ'}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Tổng mệnh giá nạp thành công</div>}
                labelCol={{ span: 12 }}
                name='quantityPriceSuccess'
              >
                {formatMoney(form.getFieldValue('quantityPriceSuccess'), 'đ')}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Tổng mệnh giá nạp thất bại</div>}
                labelCol={{ span: 12 }}
                name='quantityPriceFailed'
              >
                {formatMoney(form.getFieldValue('quantityPriceFailed'), 'đ')}
              </Form.Item>

              <Form.Item
                label={<div className='text-wrap'>Mã GD thanh toán</div>}
                labelCol={{ span: 15 }}
                name='transactionId'
                className='truncate'
              >
                {form.getFieldValue('transactionId') && (
                  <div
                    className='text-blue-600 underline cursor-pointer'
                    onClick={() =>
                      dispatch(
                        setModalState({ open: true, content: 'top-up-package-info', width: '50%' }),
                      )
                    }
                  >
                    <span>{form.getFieldValue('transactionId')}</span>
                  </div>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label='Người tạo' labelCol={{ span: 12 }} name='createdBy'>
                {form.getFieldValue('createdBy')}
              </Form.Item>

              <Form.Item
                label='Thời gian tạo'
                labelCol={{ span: 12 }}
                name='createdAt'
                valuePropName={
                  form.getFieldValue('createdAt')
                    ? formatDateTime(form.getFieldValue('createdAt'))
                    : ''
                }
              >
                {form.getFieldValue('createdAt')
                  ? formatDateTime(form.getFieldValue('createdAt'))
                  : ''}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Người cập nhật gần nhất</div>}
                labelCol={{ span: 12 }}
                name='updatedBy'
              >
                {form.getFieldValue('updatedBy')}
              </Form.Item>
              <Form.Item
                label={<div className='text-wrap'>Ngày cập nhật gần nhất</div>}
                labelCol={{ span: 12 }}
                name='updatedAt'
              >
                {form.getFieldValue('updatedAt')
                  ? new Date(form.getFieldValue('updatedAt')).getTime() ===
                    new Date(form.getFieldValue('createdAt')).getTime()
                    ? ''
                    : formatDateTime(form.getFieldValue('updatedAt'))
                  : ''}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} className='mb-[1rem]'>
            <Col span={6}>
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setRadio(e.target.value);
                }}
                disabled={
                  statusPackage === OrderStatusEnum.EDIT ||
                  (statusPackage === OrderStatusEnum.New && !id)
                    ? false
                    : true
                }
                value={radio}
              >
                <Radio value={1}>Nhập STB</Radio>
                <Radio value={2}>Import file</Radio>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={16}>
            {radio === 1 ? (
              <Fragment>
                <Col span={8}>
                  <Form.Item
                    labelCol={{ span: 12 }}
                    name='phone_number'
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng nhập số thuê bao',
                      },
                      {
                        min: 10,
                        message: 'Vui lòng nhập đủ số thuê bao',
                      },
                    ]}
                  >
                    <Input
                      type='number'
                      maxLength={11}
                      onChange={(e) => {
                        if (e.target.value.length <= 11) {
                          form.setFieldValue('phone_number', e.target.value);
                        } else {
                          form.setFieldValue('phone_number', e.target.value.substring(0, 11));
                        }
                        e.preventDefault();
                      }}
                      onKeyDown={(evt) => {
                        if (!evt.ctrlKey && !evt.metaKey) {
                          if (
                            evt.key === 'e' ||
                            (!isFinite(Number(evt.key)) && evt.key !== 'Backspace') ||
                            evt.key === '+' ||
                            evt.key === '-' ||
                            evt.key === '.' ||
                            Number(evt.key) > 9 ||
                            Number(evt.key) < 0
                          ) {
                            evt.preventDefault();
                          }
                        }
                      }}
                      disabled={
                        statusPackage === OrderStatusEnum.EDIT ||
                        (statusPackage === OrderStatusEnum.New && !id)
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <Button
                  onClick={() => {
                    form
                      .validateFields(['topupName', 'phone_number'])
                      .then(() => {
                        if (radio === 1) {
                          if (!pathFile) {
                            addPhoneNumber(form.getFieldValue('phone_number'));
                          } else {
                            setOpenModalConfirm(true);
                          }
                        } else {
                          setOpenModalConfirm(true);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }}
                  disabled={
                    statusPackage === OrderStatusEnum.EDIT ||
                    (statusPackage === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                >
                  Thêm
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                {!fileImport && order?.fileUrl && (
                  <Col>
                    <a href={order?.fileUrl} download>
                      <Button type='link'>{order?.fileName || 'Excel file'}</Button>
                    </a>
                  </Col>
                )}
                <Upload
                  disabled={
                    statusPackage === OrderStatusEnum.EDIT ||
                    (statusPackage === OrderStatusEnum.New && !id)
                      ? false
                      : true
                  }
                  {...props}
                  fileList={fileImport ? [fileImport] : []}
                >
                  <Button
                    disabled={
                      statusPackage === OrderStatusEnum.EDIT ||
                      (statusPackage === OrderStatusEnum.New && !id)
                        ? false
                        : true
                    }
                  >
                    Chọn file
                  </Button>
                </Upload>
                <Button
                  disabled={
                    (statusPackage === OrderStatusEnum.EDIT ||
                      (statusPackage === OrderStatusEnum.New && !id)) &&
                    fileImport
                      ? false
                      : true
                  }
                  className='mx-[6px] w-[120px]'
                  onClick={() => {
                    if (id) {
                      setOpenModalConfirm(true);
                    } else {
                      handleImport();
                    }
                  }}
                >
                  Import
                </Button>
                <Button className='ml-2'>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/api/topup-provider/export-excel-template`}
                  >
                    Export template
                  </a>
                </Button>
              </Fragment>
            )}
          </Row>
        </Form>
      </BoxWithLabel>
      <BoxWithLabel title='Danh sách thuê bao nạp tiền'>
        <Tabs
          tabBarExtraContent={operations}
          defaultActiveKey='1'
          type='card'
          size='middle'
          onTabClick={(activeKey: string) => {
            setActiveTab(activeKey);
            const data = params;
            data.pageIndex = 1;
            data.pageSize = 10;
            setSearchParams(data);
          }}
          items={new Array(2).fill(null).map((_, i) => {
            const id = String(i + 1);
            return {
              label: id === '1' ? `Danh sách đúng` : 'Danh sách sai',
              key: id,
              children: (
                <TableContent
                  scroll={{
                    x: 'max-content',
                  }}
                  locale={{ emptyText: 'Không tìm thấy kết quả' }}
                  totalRecord={id === '1' ? listSuccess?.length : listErrors?.length}
                  dataSource={
                    id === '1' ? filterDataSource(listSuccess) : filterDataSource(listErrors)
                  }
                  columns={id === '1' ? columns : columnsFail}
                  // onChange={() => {}}
                  // onPageChange={() => {}}
                />
              ),
            };
          })}
        />
      </BoxWithLabel>
      <ModalConfirm
        open={openModalConfirm}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Bạn chắc chưa?</div>
            <p>Sau khi thêm sẽ xóa dữ liệu trước đó</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button
                onClick={() => {
                  // Import serial
                  if (radio === 1) {
                    addPhoneNumber(form.getFieldValue('phone_number'));
                  }
                  //import excel
                  else {
                    handleImport();
                  }
                  setOpenModalConfirm(false);
                }}
                className='h-full w-full'
              >
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirm(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmDelete}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Xoá đơn hàng?</div>
            <p>Bạn có chắc chắn muốn xoá đơn hàng nạp tiền này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleDeletePackage} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmDelete(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmCancel}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Huỷ đơn hàng?</div>
            <p>Bạn có muốn thực hiện thay đổi?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleCancelTopup} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmCancel(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmTopup}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Nạp tiền?</div>
            <p>Bạn có muốn thực hiện nạp tiền cho đơn hàng này?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleTopup} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  setOpenModalConfirmTopup(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      <ModalConfirm
        open={openModalConfirmChange}
        content={
          <div className='pb-0 p-8'>
            <div className='font-black text-3xl mb-4'>Đổi mệnh giá?</div>
            <p>Danh sách số thuê bao sẽ bị đổi mệnh giá. Bạn có muốn thực hiện?</p>
            <div className='flex flex-col md:flex-row gap-4 h-[54px] w-full mt-12 mb-16 md:mb-0'>
              <Button onClick={handleChangeTopup} className='h-full w-full'>
                XÁC NHẬN
              </Button>
              <Button
                className='h-full w-full'
                onClick={() => {
                  form.setFieldValue('topupName', tempChange);
                  setOpenModalConfirmChange(false);
                }}
              >
                THOÁT
              </Button>
            </div>
          </div>
        }
      />
      {isOpenModalCheckEsimExpired && (
        <ModalCheckEsimExpired
          isOpen={isOpenModalCheckEsimExpired}
          setIsOpenModal={setIsOpenModalCheckEsimExpired}
          listEsimExpired={listEsimExpired}
          listEsimValid={listEsimValid}
          onClickConfirm={onClickConfirmEsimExpired}
          onClickCancel={onCancelEsimExpired}
        />
      )}
    </Fragment>
  );
}

export default CreateTopupPage;

const SelectItemTable = ({
  dataTopupName,
  item,
  statusPackage,
  id,
  editTopupNameLine,
  onChange,
  onDropdownVisibleChange,
}: any) => {
  const [tempTopupLine, setTempTopupLine] = useState<string[]>(item.topupName);

  return (
    <Select
      showSearch
      placeholder='Vui lòng chọn'
      options={dataTopupName}
      value={tempTopupLine}
      removeIcon={<></>}
      mode='multiple'
      disabled={
        statusPackage === OrderStatusEnum.EDIT || (statusPackage === OrderStatusEnum.New && !id)
          ? false
          : true
      }
      onDropdownVisibleChange={(open) => {
        onDropdownVisibleChange(open, tempTopupLine)
      }}
      onChange={(e) => {
        setTempTopupLine(e);
        // onChange(e);
      }}
    />
  );
};
